import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { DeviceSensorAlarmsSchema, LocaleSchema } from "~/services/api-schemas";
import { http } from "~/services/http";

export async function fetchLocales() {
  const { data } = await http.get({
    path: "locales",
    schema: z.object({ locales: z.array(LocaleSchema) }),
  });

  return data.locales;
}

export async function fetchDeviceSensorAlarms(deviceId: string, noTenantId: boolean) {
  const { data } = await http.get({
    // TODO: This path needs to be changed, as device sensor alarms are independent of the deviceId.
    path: `devices/${deviceId}/device_sensor_alarms`,
    schema: z.object({
      data: z.array(DeviceSensorAlarmsSchema),
    }),
    noTenantId,
  });

  return data.data;
}

export function useLocaleOptions() {
  return useQuery({
    queryKey: ["locales"],
    queryFn: () => fetchLocales(),
    select: (data) =>
      data.map<{ label: string; value: string }>((e) => ({
        label: e.displayName,
        value: e.key,
      })),
  });
}
